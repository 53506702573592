<template>
  <div class="grid-x grid-margin-x">
    <div class="cell auto">
      <data-table
        icon="icon-menu-profile"
        :title="$t('metrics.brand_usage')"
        :headers="page_headers"
        :items="pages"
      ></data-table>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'MetricsBrandUsage',
  data () {
    return {
      page_headers: ["Pagina's", 'Bekeken', 'Downloads', 'Shares'],
      pages: []
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/activity/pages`).then(response => {
      for (let i = 0; i <= response.data.length; i++) {
        const page = {
          name: response.data[i].name,
          viewed: response.data[i].show_count,
          downloads: response.data[i].downloads_count,
          shares: response.data[i].shares_count
        }

        this.pages.push(page)
      }
    })
  }
}
</script>

<style scoped></style>
