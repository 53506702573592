import { render, staticRenderFns } from "./MetricsBrandUsage.vue?vue&type=template&id=303b0495&scoped=true"
import script from "./MetricsBrandUsage.vue?vue&type=script&lang=js"
export * from "./MetricsBrandUsage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303b0495",
  null
  
)

export default component.exports