<template>
  <div class="grid-x grid-margin-x" v-if="users">
    <div class="cell auto">
      <data-table
        icon="icon-menu-profile"
        :title="$t('metrics.user_performance')"
        :headers="user_performance_headers"
        :items="users"
      ></data-table>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'MetricsUserPerformance',
  data () {
    return {
      user_performance_headers: [
        'Gebruiker',
        'Sessies',
        "Pagina's",
        'Downloads',
        'Shares'
      ],
      users: []
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/activity/users`).then(response => {
      for (let i = 0; i <= response.data.length; i++) {
        const user = {
          name: {
            name: response.data[i].name,
            avatar: response.data[i].avatar.url
          },
          sessions: response.data[i].sessions_count,
          viewed: response.data[i].pages_count,
          downloads: response.data[i].downloads_count,
          shares: response.data[i].shares_count
        }

        this.users.push(user)
      }
    })
  }
}
</script>

<style scoped></style>
