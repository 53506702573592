<template>
  <div v-if="users">
    <div class="grid-x grid-margin-x">
      <div class="cell auto">
        <data-table
          icon="icon-menu-profile"
          :title="$t('metrics.user_performance')"
          :headers="user_performance_headers"
          :items="users"
          :readmore="true"
          :readmoreRoute="'MetricsUserPerformance'"
        ></data-table>
        <div class="spacer--20"></div>
        <data-table
          icon="icon-brand-usage"
          :title="$t('metrics.brand_usage')"
          :headers="page_headers"
          :items="pages"
          :readmore="true"
          :readmoreRoute="'MetricsBrandUsage'"
        ></data-table>
        <div class="spacer--20"></div>
        <data-table
          icon="icon-downloads"
          :title="$t('assets.assets')"
          :headers="asset_headers"
          :items="assets"
          :readmore="true"
          :readmoreRoute="'MetricsAssetsUsage'"
        ></data-table>
      </div>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'MetricsOverview',
  data () {
    return {
      user_performance_headers: [
        'Gebruiker',
        'Sessies',
        'Pagina\'s',
        'Downloads',
        'Shares'
      ],
      users: [],
      page_headers: ['Pagina\'s', 'Bekeken', 'Downloads', 'Shares'],
      pages: [],
      asset_headers: ['Paginas', 'Bekeken', 'Downloads', 'Shares'],
      assets: []
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/activity/users`).then(response => {
      for (let i = 0; i <= response.data.length; i++) {
        // stop after 5 users
        if (this.users.length === 5) break

        const user = {
          name: {
            name: response.data[i].name,
            avatar: response.data[i].avatar.url
          },
          sessions: response.data[i].sessions_count,
          viewed: response.data[i].pages_count,
          downloads: response.data[i].downloads_count,
          shares: response.data[i].shares_count
        }

        this.users.push(user)
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/activity/pages`).then(response => {
      for (let i = 0; i <= response.data.length; i++) {
        // stop after 5 pages
        if (this.pages.length === 5) break

        const page = {
          name: response.data[i].name,
          viewed: response.data[i].show_count,
          downloads: response.data[i].downloads_count,
          shares: response.data[i].shares_count
        }

        this.pages.push(page)
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/activity/assets`).then(response => {
      for (let i = 0; i <= response.data.length; i++) {
        // stop after 5 assets
        if (this.assets.length === 5) break

        const asset = {
          name: response.data[i].title,
          viewed: response.data[i].show_count,
          downloads: response.data[i].downloads_count,
          shares: response.data[i].shares_count
        }

        this.assets.push(asset)
      }
    })
  }
}
</script>

<style scoped></style>
